<template>
  <Table :columns="columns" class="shadow max-h-[75vh] mt-4 relative">
    <tr class="sticky top-15 z-1 bg-primary-50 border-b border-neutral-80">
      <TableCell></TableCell>
      <TableCell>
        <InputField
          :placeholder="$t('shared.label.search')"
          prefix-icon="fa-magnifying-glass"
          @inputChange="({ value }) => onFilter(value, 'source')"
        />
      </TableCell>
      <TableCell>
        <InputField
          :placeholder="$t('shared.label.search')"
          prefix-icon="fa-magnifying-glass"
          @inputChange="({ value }) => onFilter(value, 'target')"
        />
      </TableCell>
      <TableCell>
        <InputField
          :placeholder="$t('shared.label.search')"
          prefix-icon="fa-magnifying-glass"
          @inputChange="({ value }) => onFilter(value, 'correctedTarget')"
        />
      </TableCell>
      <TableCell v-if="aiTraining" overflowVisible>
        <Select>
          <SelectSelectionCustom
            data-cy="status_select"
            :placeholder="$t('customer.lang_assets.entry_type.title')"
            :value="
              tableFilter.status === 'all'
                ? $t('customer.lang_assets.content_type.all')
                : $t(`customer.training_data.ai_training.${tableFilter.status}`)
            "
          >
            <SelectOptionCustom
              value="all"
              :selected="tableFilter.status === 'all'"
              @inputChange="onFilter($event, 'status')"
            >
              <span>{{ $t('customer.lang_assets.content_type.all') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="not_used"
              :selected="tableFilter.status === 'not_used'"
              @inputChange="onFilter($event, 'status')"
            >
              <span>{{
                $t('customer.training_data.ai_training.not_used')
              }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="used"
              :selected="tableFilter.status === 'used'"
              @inputChange="onFilter($event, 'status')"
            >
              <span>{{ $t('customer.training_data.ai_training.used') }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </TableCell>
      <TableCell overflowVisible>
        <Select>
          <SelectSelectionCustom
            data-cy="entryType_select"
            :placeholder="$t('customer.lang_assets.entry_type.title')"
            :value="
              $t(`customer.lang_assets.content_type.${tableFilter.entryType}`)
            "
          >
            <SelectOptionCustom
              value="all"
              :selected="tableFilter.entryType === 'all'"
              @inputChange="onFilter($event, 'entryType')"
            >
              <span>{{ $t('customer.lang_assets.content_type.all') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="human"
              :selected="tableFilter.entryType === 'human'"
              @inputChange="onFilter($event, 'entryType')"
            >
              <span>{{ $t('customer.lang_assets.content_type.human') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="synthetic"
              :selected="tableFilter.entryType === 'synthetic'"
              @inputChange="onFilter($event, 'entryType')"
            >
              <span>{{
                $t('customer.lang_assets.content_type.synthetic')
              }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </TableCell>
      <TableCell v-if="aiTraining" overflowVisible>
        <Select>
          <SelectSelectionCustom
            data-cy="usage_select"
            :placeholder="$t('customer.lang_assets.entry_type.title')"
            :value="getUsageValue"
          >
            <SelectOptionCustom
              value="all"
              :selected="tableFilter.usage === 'all'"
              @inputChange="onFilter($event, 'usage')"
            >
              <span>{{ $t('customer.lang_assets.content_type.all') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="global"
              :selected="tableFilter.usage === 'global'"
              @inputChange="onFilter($event, 'usage')"
            >
              <span>{{ $t('customer.training_data.ai_training.global') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              :value="currentAccountId"
              :selected="tableFilter.usage === currentAccountId"
              @inputChange="onFilter($event, 'usage')"
            >
              <span>{{ capitalizeFirstLetter(currentAccountId) }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              v-for="(account, i) in allSubAccounts"
              :key="i"
              :value="account.team_identifier"
              :selected="tableFilter.usage === account.team_identifier"
              @inputChange="onFilter($event, 'usage')"
            >
              <span>{{ capitalizeFirstLetter(account.team_identifier) }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </TableCell>
      <TableCell v-if="actions"></TableCell>
    </tr>
    <template v-if="source && target">
      <template v-if="aiTraining">
        <tr v-if="!addingCustomData">
          <TableCell></TableCell>
          <TableCell :colspan="`${columns.length - 1}`">
            <BtnSecondary
              outline
              prefix="fa-plus-square"
              :title="$t('customer.training_data.ai_training.add_custom_data')"
              @click="addingCustomData = true"
            />
          </TableCell>
        </tr>
        <TrainingTableRow
          v-else
          aiTraining
          customData
          @delete="addingCustomData = false"
        />
      </template>
      <slot />
    </template>
    <tr v-else>
      <TableCell></TableCell>
      <TableCell :colspan="`${columns.length - 1}`" class="!py-10">
        <TextTiny lightNeutral>
          {{ $t('customer.training_data.tm_training.table_info') }}
        </TextTiny>
      </TableCell>
    </tr>
  </Table>
</template>
<script setup>
import TrainingTableRow from '@/components/languageAssets/trainingData/TrainingTableRow'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import TextTiny from '@/components/shared/font/text/TextTiny'
import InputField from '@/components/shared/input/InputField'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import Table from '@/components/shared/table/Table'
import TableCell from '@/components/shared/table/TableCell'
import { capitalize, capitalizeFirstLetter } from '@/helpers/format'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const $t = i18n.t

const props = defineProps({
  source: String,
  target: String,
  aiTraining: Boolean,
  actions: {
    type: Boolean,
    default: true
  }
})

const columns = ref([
  {
    field: 'checkbox',
    label: '',
    width: 30
  },
  {
    field: 'source_text',
    label: $t('shared.label.source_text'),
    doubleLabel: capitalize(props.source) || 'empty',
    width: 120
  },
  {
    field: 'target_text',
    label: $t('shared.label.target_text'),
    doubleLabel: capitalize(props.target) || 'empty',
    width: 120
  },
  {
    field: 'corrected_target_text',
    label: $t('shared.label.corrected_target'),
    doubleLabel: capitalize(props.target) || 'empty',
    width: 120
  },
  ...(props.aiTraining
    ? [
        {
          field: 'status',
          label: $t('shared.label.status'),
          doubleLabel: 'empty',
          width: 100
        }
      ]
    : []),
  {
    field: 'entry_type',
    label: $t('customer.lang_assets.entry_type.title'),
    doubleLabel: 'empty',
    width: 60
  },
  ...(props.aiTraining
    ? [
        {
          field: 'usage',
          label: $t('customer.lang_assets.segment.usage'),
          doubleLabel: 'empty',
          width: 60
        }
      ]
    : []),
  ...(props.actions
    ? [
        {
          field: 'actions',
          label: $t('shared.label.actions'),
          doubleLabel: 'empty',
          width: 40
        }
      ]
    : [])
])

const tableFilter = ref({
  source: '',
  target: '',
  correctedTarget: '',
  status: 'all',
  entryType: 'all',
  usage: 'all'
})
const addingCustomData = ref(false)

const allSubAccounts = computed(() => store.state.account.allSubAccounts)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)

const getUsageValue = computed(() => {
  switch (tableFilter.value.usage) {
    case 'all':
      return $t('customer.lang_assets.content_type.all')
    case 'global':
      return $t('customer.training_data.ai_training.global')
    default:
      return capitalizeFirstLetter(tableFilter.value.usage)
  }
})

function onFilter(value, type) {
  tableFilter.value[type] = value
}

watch(
  () => ({
    source: props.source,
    target: props.target
  }),
  (newValues) => {
    if (!newValues.source || !newValues.target) return
    columns.value[1].doubleLabel = capitalize(newValues.source)
    columns.value[2].doubleLabel = capitalize(newValues.target)
    columns.value[3].doubleLabel = capitalize(newValues.target)
  }
)
</script>
