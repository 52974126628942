<template>
  <tr class="border-b border-neutral-80">
    <TableCell>
      <CheckboxBtn
        v-if="!customData"
        :data-cy="`select_${i}`"
        class="!mr-0 mt-1"
        :is-active="false"
        @click.prevent.stop="checkboxClick(segment.id)"
      />
    </TableCell>
    <TableCell>
      <Textarea
        data-cy="source_textarea"
        white
        :rtl="false"
        :error="!textChange.source && customData"
        :value="textChange.source"
        @inputChange="onTextValue($event, 'source')"
      />
    </TableCell>
    <TableCell>
      <Textarea
        data-cy="target_textarea"
        white
        :rtl="false"
        :error="!textChange.target && customData"
        :value="textChange.target"
        @inputChange="onTextValue($event, 'target')"
      />
    </TableCell>
    <TableCell>
      <Textarea
        data-cy="corrected_textarea"
        white
        :rtl="false"
        :error="!textChange.corrected"
        :value="textChange.corrected"
        @inputChange="onTextValue($event, 'corrected')"
      />
    </TableCell>
    <TableCell v-if="aiTraining" overflowVisible>
      <Select>
        <SelectSelectionCustom
          :data-cy="`status_select_${index}`"
          sm
          :theme="
            statusValue === $t('customer.training_data.ai_training.used')
              ? 'success'
              : 'error'
          "
          :disabled="customData"
          :value="statusValue"
        >
          <SelectOptionCustom value="used">
            <span>{{ $t('customer.training_data.ai_training.used') }}</span>
          </SelectOptionCustom>
          <SelectOptionCustom value="not_used">
            <span>{{ $t('customer.training_data.ai_training.not_used') }}</span>
          </SelectOptionCustom>
        </SelectSelectionCustom>
      </Select>
    </TableCell>
    <TableCell>
      <TextSmall medium lightNeutral>
        {{ capitalize($t('customer.lang_assets.content_type.human')) }}
      </TextSmall>
    </TableCell>
    <TableCell v-if="aiTraining" :overflowVisible="isAdmin">
      <Select v-if="isAdmin">
        <SelectSelectionCustom
          :data-cy="`usage_select_${index}`"
          sm
          theme="gray"
          :value="capitalize(getUsageValue)"
        >
          <SelectOptionCustom value="global" @inputChange="onUsageSelect">
            <span>{{
              capitalize($t('customer.training_data.ai_training.global'))
            }}</span>
          </SelectOptionCustom>
          <SelectOptionCustom
            :value="currentAccountId"
            @inputChange="onUsageSelect"
          >
            <span>{{ capitalize(currentAccountId) }}</span>
          </SelectOptionCustom>
          <SelectOptionCustom
            v-for="(account, i) in allSubAccounts"
            :key="i"
            :value="account.team_identifier"
            @inputChange="onUsageSelect"
          >
            <span>{{ capitalize(account.team_identifier) }}</span>
          </SelectOptionCustom>
        </SelectSelectionCustom>
      </Select>
      <TextSmall v-else medium lightNeutral>
        {{ capitalize(usage) }}
      </TextSmall>
    </TableCell>
    <TableCell v-if="actions" class="text-center">
      <BtnSecondary neutral outline @click="emit('delete')">
        <template #iconStart>
          <font-awesome-icon icon="trash" />
        </template>
      </BtnSecondary>
    </TableCell>
  </tr>
</template>
<script setup>
// import { isRightToLeftLanguage } from '@/helpers/editor'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import TextSmall from '@/components/shared/font/text/TextSmall'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import TableCell from '@/components/shared/table/TableCell'
import Textarea from '@/components/shared/textarea/Textarea'
import { capitalize } from '@/helpers/format'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const $t = i18n.t

const emit = defineEmits(['delete'])
const props = defineProps({
  index: {
    type: String,
    default: 'custom'
  },
  segment: Object,
  aiTraining: Boolean,
  customData: {
    type: Boolean,
    default: false
  },
  actions: {
    type: Boolean,
    default: true
  }
})
const usage = ref('global')
const textChange = ref({
  source: props.segment?.attributes?.source || '',
  target: props.segment?.attributes?.target || '',
  corrected: props.segment?.attributes?.corrected || ''
})

const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const allSubAccounts = computed(() => store.state.account.allSubAccounts)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)

const getUsageValue = computed(() => {
  switch (usage.value) {
    case 'global':
      return $t('customer.training_data.ai_training.global')
    default:
      return usage.value
  }
})

const statusValue = computed(() => {
  const used = $t('customer.training_data.ai_training.used')
  const notUsed = $t('customer.training_data.ai_training.not_used')
  return props.customData ? notUsed : segment?.attributes?.used ? used : notUsed
})

function onTextValue(value, type) {
  textChange.value[type] = value
}

function onUsageSelect(value) {
  usage.value = value
}
</script>
